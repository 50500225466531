<template>
  <div id="brand-container" class="auth-aside d-none d-md-block col-4 fxt-bg-img p-0" :style="style">

    <!--start::Logo-->
    <div class="row d-flex justify-content-start px-10 py-7 mx-0">
      <a href="login-19.html" class="fxt-logo mb-0">
        <img :src="asideLogo" alt="Logo" style="max-width:175px">
      </a>
    </div>
    <!--end::Logo-->

    <div class="d-flex align-items-center h-100 w-100 px-10 text-center position-absolute top-0">

      <!--start::Text Content-->
      <div v-if="showWelcomeContent" class="row d-flex justify-content-center mx-10 w-100">
        <h3 class="aside-title mb-5 text-white w-100 font-weight-bold" :class="{ 'text-left' : asideMessage != null, 'text-center' : asideMessage == null}">{{ asideTitle }}</h3>
        <p v-html="asideMessage" class="aside-message mb-20 text-white text-left" style="font-size:1.1rem; line-height:2rem"></p>
      </div>
      <!--end::Text Content-->

      <div></div>
    </div>

    <AsideFooter
      :domainType="domainType"
    >
    </AsideFooter>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AsideFooter from "./aside/Footer.vue";

export default {
  name: "AuthAside2",

  props: {
    domainType: { type: Number, required: true }
  },

  components: {
    AsideFooter
  },

  methods: {
    hexToRgb: function(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var resultObject = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;

      if (Object.keys(resultObject).length == 3) {
        return "rgba(" + resultObject.r + "," + resultObject.g + "," + resultObject.b + ", 0.75)";
      } else {
        return null;
      }
    },
    getBrandConfigByKey: function(key) {
      let configEntry = this.projectBrandList.filter(function(brand) {
        return brand.key == key;
      })[0];

      return (configEntry == undefined) ? null : configEntry;
    }
  },

  computed: {
    ...mapGetters([
      "projectBrandList"
    ]),

    upperHex: function() {
      let configEntry = this.getBrandConfigByKey(3);
      if (this.domainType == 3 || this.domainType == 4) {
        return this.hexToRgb(configEntry.decodedOptions[0]) + " 16%";
      } else {
        return null;
      }
    },

    lowerHex: function() {
      let configEntry = this.getBrandConfigByKey(3);
      if (this.domainType == 3 || this.domainType == 4) {
        return this.hexToRgb(configEntry.decodedOptions[1]) + " 100%";
      } else {
        return null;
      }
    },

    style: function() {
      let style = "";
      let backgroundConfigEntry = this.getBrandConfigByKey(1);
      let styleConfigEntry      = this.getBrandConfigByKey(3);

      if (this.domainType == 3 || this.domainType == 4) {
        if (styleConfigEntry.value > 1) {
          style = style + "background: linear-gradient(180deg, " + this.upperHex + ", " + this.lowerHex + ")";
        }

        if (backgroundConfigEntry.project_asset.length > 0) {
          style = style + "; background-repeat: no-repeat !important";
          style = style + "; background-position: center !important";

          if (styleConfigEntry.value > 1) {
            style = `${style}; background-image: linear-gradient(180deg, ${this.upperHex}, ${this.lowerHex}), url(${backgroundConfigEntry.project_asset[0].signedUrl}) !important`;
          } else {
            style = style + "; background-image: url('" + backgroundConfigEntry.project_asset[0].signedUrl + "') !important";
          }
        }
      } else {
        style = null;
      }

      return style;
    },

    asideTitle: function() {
      let configEntry = this.getBrandConfigByKey(11);
      if (this.domainType == 3 || this.domainType == 4) {
        return configEntry.decodedOptions.title;
      } else {
        return `${this.$t("OTHER.ASIDE.GENERIC_TITLE_" + this.domainType)}`;
      }
    },
    
    asideMessage: function() {
      let configEntry = this.getBrandConfigByKey(11);
      if (this.domainType == 3 || this.domainType == 4) {
        return configEntry.decodedOptions.message;
      } else {
        return null;
      }
    },

    asideLogo: function() {
      let configEntry = this.getBrandConfigByKey(8);
      
      if (this.domainType == 1 || this.domainType == 2) {
        return "/media/logos/toluna-logo.png";
      } else {
        if (configEntry.project_asset.length > 0) {
          return configEntry.project_asset[0].signedUrl;
        } else {
          return "/media/logos/toluna-logo.png";
        }
      }
    },

    showWelcomeContent: function() {
      if ([3,4].includes(this.domainType)) {
        let configEntry = this.getBrandConfigByKey(4);
        return (configEntry.value == 2) ? false : true;
      } else {
        return true;
      }
    }
  }
}
</script>