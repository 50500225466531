<template>
  <div
    class="row d-flex position-absolute bottom-0 w-100 mx-0 py-3 px-10"
  >
    <div class="row d-flex justify-content-between mx-0 w-100">
      <Helpdesk class="px-2" :buttonType="2"></Helpdesk>

      <a
        v-if="[3,4].includes(domainType) && welcomePlacement.value == 2 && welcomeMessage.value == 1"
        href="javascript:void(0)"
        class="text-white"
        @click="$refs['welcome-modal'].show()"
      >
        {{ $t('TOOLBAR.COMMUNITY.TOOLTIP') }}
      </a>

    </div>
    
    <b-modal
      v-if="[3,4].includes(domainType) && welcomePlacement.value == 2 && welcomeMessage.value == 1"
      size="xl"
      id="welcome-modal"
      ref="welcome-modal"
      :title="welcomeMessage.decodedOptions.title"
      body-class="px-5 py-7"
      :hide-footer="true"
    >
      <span v-html="welcomeMessage.decodedOptions.message" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Helpdesk from "@/modules/together-helpers/help/Helpdesk.vue";

export default {
  name: "AsideFooter",

  props: {
    domainType: { type: Number, required: true }
  },

  components: {
    Helpdesk
  },

  methods: {
    getBrandConfigByKey: function(key) {
      let configEntry = this.projectBrandList.filter(function(brand) {
        return brand.key == key;
      })[0];

      return (configEntry == undefined) ? null : configEntry;
    }
  },

  computed: {
    ...mapGetters([
      "projectBrandList"
    ]),
    welcomeMessage: function() {
      return this.getBrandConfigByKey(11);
    },
    welcomePlacement: function() {
      return this.getBrandConfigByKey(4);
    }
  }
}
</script>