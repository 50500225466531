<template>
  <div>
    <Loader v-if="!loadingComplete" :logo="loaderLogo"></Loader>
    <section v-else class="fxt-template-animation fxt-template-layout19 loaded">
      <div class="container-fluid px-0">
        <div class="row mx-0">
          <AuthAside
            style="max-height:100vh"
            ref="aside-container"
            :domainType="domainType"
          >
          </AuthAside>
            <div
              class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 fxt-bg-color py-0 px-5 d-flex"
              :class="alignment"
            >
              <router-view
                :domainType="domainType"
                :subDomain="subDomain"
                :activityRedirect="activityRedirect"
                :dimensions="{
                  width: $vssWidth,
                  height: $vssHeight
                }"
              >
              </router-view>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from "vuex";
import { VueScreenSizeMixin } from 'vue-screen-size';

import i18n from "@/core/plugins/vue-i18n";

import { VERIFY_TOKEN, VERIFY_AUTH } from "@/modules/together-authentication/store/auth.module";
import { POPULATE_BASIC_PROFILE_QUESTIONS } from "@/core/services/store/project/project_basic_profile_questions.module";
import { POPULATE_EXTENDED_PROFILE_QUESTIONS } from "@/core/services/store/project/project_extended_profile_questions.module";
import {
  POPULATE_COUNTRIES,
  POPULATE_GENDERS
} from "@/core/services/store/system/system.module.js";

import PageLoader from "@/core/classes/PageLoader.js";

import HtmlClass from "@/core/services/htmlclass.service";
import Loader from "@/modules/together-helpers/components/application-components/Loader.vue";
import AuthAside from "../pages/auth2/Aside"

export default {
  mixins: [VueScreenSizeMixin],

  name: "Auth2Layout",

  data() {
    return {
      pageLoader: new PageLoader(7),
      error: {
        active: false,
        message: ""
      }
    };
  },

  components: {
    Loader,
    AuthAside
  },

  beforeMount() {
    if (["login","register"].includes(this.$route.name)) {
      this.validateAuth((this.$route.name == "login") ? true : false);
    } else {
      this.pageLoader.setCompleteComponent(1);
    }

    HtmlClass.init(this.layoutConfig());
  },

  mounted() {
    this.fetchSuitableLanguage();

    if (this.domainType == 3 || this.domainType == 4) {
      this.fetchSuitableLanguage();
      this.fetchProjectBrand();
      this.fetchProjectBasicProfileQuestions();
      this.fetchProjectExtendedProfileQuestions();
      this.fetchSystemGenders();
      this.fetchSystemCountries();
    } else {
      i18n.locale = 'en';
      this.pageLoader.setCompleteComponent(7);
    }
  },

  methods: {
    validateAuth: function(redirect) {
      this.$store.dispatch(VERIFY_AUTH, 1)
        .then((res) => {
          if (redirect) {
            if (this.activityRedirect != null) {
              this.$router.push({ name: 'view_activity', params: { id: this.activityRedirect.activity, responseId: this.activityRedirect.response }});
            } else {
              window.location.href = res.data.data.redirect;
            }            
          }
        })
        .catch(() => {
          this.pageLoader.setCompleteComponent(1);
        })
    },
    fetchSuitableLanguage: function() {
      let payload = {
        route: `api/v1/language`,
        domain: (this.domainType == 4) ? this.projectIdentifier : window.location.host.split('.')[0],
      };

      this.$store.dispatch(GENERIC_RESOURCE,payload).then((res) => {
        i18n.locale = res.trim();
        this.pageLoader.setCompleteComponent();
      });
    },
    fetchProjectBrand: function() {
      let payload = {
        route: `api/v1/projects/${window.location.host.split('.')[0]}/project_brands`,
        params: {
          domain: (this.domainType == 4) ? this.projectIdentifier : window.location.host.split('.')[0],
          appendSignedUrls: 1
        }
      };
      this.$store.dispatch(GENERIC_RESOURCE,payload)
        .then((res) => {
          let projectBrandList = res._embedded.project_brands;
          this.setProjectBrandList(projectBrandList);
          this.setProjectBrandObject(projectBrandList);
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = "We're having some issues retrieving the project branding, please check back later or contact the helpdesk";
        });
    },
    fetchProjectBasicProfileQuestions: function() {
      let payload = {
        projectId: window.location.host.split('.')[0],
        params: {
          domain: (this.domainType == 4) ? this.projectIdentifier : window.location.host.split('.')[0],
        }
      };
      this.$store
        .dispatch(POPULATE_BASIC_PROFILE_QUESTIONS, payload)
        .then(() => { this.pageLoader.setCompleteComponent(); })
        .catch(() => {
          this.error.active = true;
          this.error.message = "We're having some issues retrieving the project basic profile questions, please check back later or contact the helpdesk";
        });
    },
    fetchProjectExtendedProfileQuestions: function() {
      let payload = {
        projectId: window.location.host.split('.')[0],
        params: {
          domain: (this.domainType == 4) ? this.projectIdentifier : window.location.host.split('.')[0],
          extendedProfileChoices: 'with',
          projectAssets: 'with'
        }
      };
      this.$store
        .dispatch(POPULATE_EXTENDED_PROFILE_QUESTIONS, payload)
        .then(() => { this.pageLoader.setCompleteComponent(); })
        .catch(() => {
          this.error.active = true;
          this.error.message = "We're having some issues retrieving the project extended profile questions, please check back later or contact the helpdesk";
        });
    },
    fetchSystemGenders: function() {
      this.$store.dispatch(POPULATE_GENDERS, false)
      .then(() => { this.pageLoader.setCompleteComponent(); })
    },
    fetchSystemCountries: function() {
      this.$store.dispatch(POPULATE_COUNTRIES, false)
      .then(() => { this.pageLoader.setCompleteComponent(); })
    },
    ...mapMutations({
      setProjectBrandList: "setProjectBrandList",
      setProjectBrandObject: "setProjectBrandObject",
      setUserLanguage: "setUserLanguage"
    })
  },

  computed: {
    ...mapGetters([
      "layoutConfig",
      "systemLanguages",
      "userLanguage"
    ]),

    subDomain: function() {
      return window.location.host.split('.')[0];
    },

    domainType: function() {
      if (window.location.host.includes(process.env.VUE_APP_API_ROOT) == false) {
        if (this.subdomain == "input" || this.subdomain == "admin") {
          return 5; //Custom Client
        } else {
          return 4; //Custom Project
        }
      }
      if (this.subDomain.indexOf("-") >= 0) {
        return 3; //Project
      } else if (this.subDomain != "input" && this.subDomain != "admin") {
        return 2; //Client 
      } else {
        return 1; //Core
      }
    },

    projectIdentifier: function() {
      if (this.domainType == 3) {
        return this.subDomain.split('-')[0];
      } else if (this.domainType == 4) {
        return this.subDomain;
      } else {
        return null;
      }
    },

    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    loadingComplete() {
      return this.pageLoader.getStatus();
    },

    alignment: function() {
      if (this.$route.name == "register") {
        return (this.$vssWidth <= 480) ? "align-items-center" : "align-items-start";
      } else {
        return "align-items-center";
      }
    },

    activityRedirect: function() {
      let queryHash = window.location.hash;
      queryHash = queryHash.replace('#/login?', '');

      if (queryHash.includes("redirect=")) {
        queryHash = queryHash.replace('redirect=', '');
        queryHash = queryHash.split("-");
        return {
          activity: parseInt(queryHash[0]),
          response: parseInt(queryHash[1])
        };
      } else {
        return null;
      }
    }
  }
}
</script>